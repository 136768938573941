@use "./../material/material-theme-settings" as *;
.bitf-dnd-droppable-area {
  border: dashed 1px $app-primary-base;
  border-radius: 5px;
  transition: background-color ease-in 0.2s;
  height: 60px;
  display: flex;
  align-items: center;

  &.bitf-dnd-file-is-dragging {
    background-color: $app-primary-light;
  }
}
