@use "@angular/material" as mat;
@use "./_material-theme-settings" as *;

body {
  font-family: Lato;
  font-size: 14px;
}
.app-theme {
  @include mat.theme-overrides(
    (
      headline-large: 400 20px/32px Lato,
      headline-medium: 400 20px/32px Lato,
      headline-small: 400 16px/24px Lato,
      title-large: 400 16px/24px Lato,
      title-medium: 400 14px/16px Lato,
      title-small: 400 12px/20px Lato,
    )
  );

  --mat-sidenav-container-background-color: #{$app-white};
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-sidenav-content-background-color: #fafafa;
  --mat-expansion-container-background-color: #{$app-white};
  --mat-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-shape: 0px;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-container-color: transparent;
  --mdc-filled-button-container-shape: 4px;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #{$app-white};
  --mdc-outlined-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-width: 0px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-button-outline-color: #{$app-grey-base};
  --mdc-outlined-button-container-shape: 4px;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
  --mat-table-background-color: #{$app-white};
  --mat-list-active-indicator-shape: 0px;

  h1 {
    font: var(--mat-sys-headline-large) !important;
  }
  h2 {
    font: var(--mat-sys-headline-medium) !important;
  }

  h3 {
    font: var(--mat-sys-headline-small);
  }

  h4 {
    font: var(--mat-sys-title-large);
    margin-bottom: 0 !important;
  }

  h5 {
    font: var(--mat-sys-title-medium);
  }

  .mat-caption {
    font: var(--mat-sys-title-small) !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }

  button[color="accent"][mat-flat-button] {
    background-color: #{$accent-color};
  }

  .form-field-without-padding div.mat-form-field-wrapper {
    margin-bottom: -1.34375em;
    .mat-form-field-flex {
      padding-top: 0;
    }
  }

  mat-chip-option {
    &.chip-small {
      min-height: 22px !important;
      padding: 0 6px !important;
      --mdc-chip-label-text-color: #{$app-white};
      --mdc-chip-container-shape-radius: 16px;
      --mdc-chip-focus-state-layer-color: transparent;
      --mdc-chip-outline-width: 0px;
    }
  }

  .mat-mdc-table {
    td {
      padding-right: 4px !important;
    }
  }

  .mat-mdc-card {
    padding: 1em;
  }

  .mat-mdc-card-title {
    color: $primary-color;
    font-size: 24px;
  }

  .mat-snackbar {
    &-success {
      background-color: $app-success-base !important;
      color: $app-white !important;
    }

    &-error {
      background-color: $app-error-base !important;
      color: $app-white !important;
    }
  }

  mat-list {
    mat-list-item {
      .title {
        font-weight: 700 !important;
      }
    }
  }
  mat-card {
    mat-list {
      --mdc-list-list-item-one-line-container-height: auto;
      mat-list-item {
        span {
          div {
            font-size: 14px;
          }
        }
      }
    }
  }
}
