* {
  box-sizing: border-box;
  outline: none;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/lato/lato-regular.ttf");
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #032e5f;
}

*[hidden] {
  display: none !important;
}
