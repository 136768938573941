@use "sass:map";
@use "@angular/material" as mat;
@use "mat3-palettes-theme-colors" as palettes;

$primary-color: #f84716;
$accent-color: #1d5175;

/*
* Get colors from palettes
*/

// Primary
$app-primary-base: map.get(palettes.$primary-palette, 50);
$app-primary-light: map.get(palettes.$primary-palette, 70);
$app-primary-dark: map.get(palettes.$primary-palette, 30);
$app-primary-super-light: map.get(palettes.$primary-palette, 80);

// Accent
$app-accent-base: map.get(palettes.$tertiary-palette, 40);
$app-accent-light: map.get(palettes.$tertiary-palette, 95);
$app-accent-dark: map.get(palettes.$tertiary-palette, 20);

// Error - Red
$app-error-base: map.get(mat.$red-palette, 40);
$app-error-light: map.get(mat.$red-palette, 70);
$app-error-dark: map.get(mat.$red-palette, 20);

// Warning - Orange
$app-warn-base: map.get(mat.$red-palette, 40);
$app-warn-light: map.get(mat.$red-palette, 70);
$app-warn-dark: map.get(mat.$red-palette, 20);

// Success - Green
$app-success-base: map.get(mat.$green-palette, 40);
$app-success-light: map.get(mat.$green-palette, 70);
$app-success-dark: map.get(mat.$green-palette, 20);

// Gray
$app-grey-base: rgb(158, 158, 158);
$app-grey-light: rgb(158, 158, 158);
$app-grey-dark: rgb(158, 158, 158);

// Other colors
$app-white: rgb(255, 255, 255);
$app-black: rgb(0, 0, 0);
$app-background: #f8f8f8;

.text {
  &-primary {
    color: $app-primary-base !important;
  }

  &-success {
    color: $app-success-base !important;
  }

  &-info {
    color: $app-accent-base !important;
  }

  &-warning {
    color: $app-warn-base !important;
  }

  &-error {
    color: $app-error-base !important;
  }

  &-error-dark {
    color: $app-error-dark !important;
  }

  &-grey {
    color: $app-grey-base !important;
  }

  &-grey-light {
    color: $app-grey-light !important;
  }

  &-white {
    color: $app-white !important;
  }
}

.bg {
  &-transparent {
    background-color: transparent !important;
  }

  &-success {
    background-color: $app-success-base !important;
  }

  &-success-light {
    background-color: $app-success-light !important;
  }

  &-warning {
    background-color: $app-warn-base !important;
  }

  &-error {
    background-color: $app-error-base !important;
  }

  &-error-light {
    background-color: $app-error-light !important;
  }

  &-error-dark {
    background-color: $app-error-dark !important;
  }

  &-info-light {
    background-color: $app-accent-light !important;
  }

  &-grey {
    background-color: $app-grey-base !important;
  }

  &-grey-light {
    background-color: $app-grey-light !important;
  }

  &-warn {
    background-color: $app-warn-base !important;
  }

  &-warn-light {
    background-color: $app-warn-light !important;
  }
}

.border-color {
  &-success-dark {
    border-color: $app-success-dark !important;
  }

  &-error-dark {
    border-color: $app-error-dark !important;
  }

  &-warn-dark {
    border-color: $app-warn-dark !important;
  }

  &-info-dark {
    border-color: $app-accent-dark !important;
  }
}
