@use "./../material/material-theme-settings" as *;

.table-bordered {
  th,
  td {
    border: 1px solid lightgray;
    padding: 10px;
  }
  th {
    background-color: $app-primary-light;
  }
}
